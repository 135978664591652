import { SERVER_APIS } from "../constants";
import { httpClient } from "../utils";

export const paymentActionTypes = {
  Subs_Cancel: "[Subs_Cancel] Action",
  Adding_Card: "[Adding_Card] Action",
  Adding_Success: "[Adding_Success] Action",
  Adding_Failed: "[Adding_Failed] Action",
  Card_Init: "[Card_Init] Action",
  Fetching_PlanStateforIOP: "[Fetching_PlanStateforIOP] Action",
  PlanStateforIOP_Fetched: "[ PlanStateforIOP_Fetched] Action",
  Fetching_Cards: "[Fetching_Cards] Action",
  Fetched_Cards: "[Fetched_Cards] Action",
  Removed_Card: "[Removed_Card] Action",
  Fetching_Transactions: "[Fetching_Transaction] Action",
  Fetched_Transactions: "[Fetched_Transactions] Action",
  Retry_Transaction: "[Fetched_Transactions] Action",
  Retry_Transaction_Failed: "[Retry_Transaction_Failed] Action",
  Apply_Coppon_Success: "[Apply_Coppon_Succes]Action",
  Apply_Coupon_Failure: "[Apply_Coupon_Failure]Action",
  subscribing_PPC: "[Subscribing_PPC] Action",
  PPC_Subscribe: "[Subscribe_PPC] Action",
  PPC_Failed: "[Subscribe_Failed] Action",
  PPC_Cancel: "[PPC_Cancelled] Action",
  RESET_COUPON_DATA: "[RESET_COUPON_DATA] Action",
};

const initCard = () => {
  return (dispatch) => {
    return httpClient
      .get(`${SERVER_APIS.Payment_Card_Init_URL}`)
      .then(({ data: { clientSecret } }) => {
        dispatch({
          type: paymentActionTypes.Card_Init,
          payload: { clientSecret },
        });
        return Promise.resolve(clientSecret);
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: paymentActionTypes.Adding_Failed,
          payload: { error: data },
        });
        return Promise.reject();
      });
  };
};

const onFetchPlanActionforIOP = () => {
  return (dispatch) => {
    dispatch({ type: paymentActionTypes.Fetching_PlanStateforIOP });
    return httpClient
      .get(`${SERVER_APIS.IOP_Payment_Card_Init_URL}`)
      .then((response) => {
        dispatch({
          type: paymentActionTypes.PlanStateforIOP_Fetched,
          payload: { subscribePlanIOP: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: paymentActionTypes.PlanStateforIOP_Fetched,
          payload: { subscribePlanIOP: [] },
        });
        return Promise.reject();
      });
  };
};
const onAddingCard = (params) => {
  return (dispatch) => {
    dispatch({ type: paymentActionTypes.Adding_Card });
    return httpClient
      .post(`${SERVER_APIS.Payment_Card_URL}`, params)
      .then(({ data }) => {
        dispatch({
          type: paymentActionTypes.Adding_Success,
          payload: {
            card: data,
          },
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: paymentActionTypes.Adding_Failed,
          payload: { error: data },
        });
        return Promise.reject();
      });
  };
};

const onGetCards = () => {
  return (dispatch) => {
    dispatch({ type: paymentActionTypes.Fetching_Cards });
    return httpClient
      .get(`${SERVER_APIS.Payment_Card_URL}`)
      .then((response) => {
        dispatch({
          type: paymentActionTypes.Fetched_Cards,
          payload: { cards: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const onRemoveCard = (cardId) => {
  return (dispatch) => {
    return httpClient
      .delete(`${SERVER_APIS.Payment_Card_URL}/${cardId}`)
      .then((response) => {
        dispatch({
          type: paymentActionTypes.Removed_Card,
          payload: { cardId },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const getTransactions = () => {
  return (dispatch) => {
    dispatch({ type: paymentActionTypes.Fetching_Transactions });
    return httpClient
      .get(`${SERVER_APIS.Payment_Transaction_URL}`)
      .then((response) => {
        dispatch({
          type: paymentActionTypes.Fetched_Transactions,
          payload: { transactions: response.data },
        });
        return Promise.resolve();
      })
      .catch(() => Promise.reject());
  };
};

const retryTransaction = (cardId) => {
  return (dispatch) => {
    return httpClient
      .patch(`${SERVER_APIS.Payment_Transaction_URL}/${cardId}`)
      .then((response) => {
        dispatch({
          type: paymentActionTypes.Retry_Transaction,
          payload: {},
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: paymentActionTypes.Retry_Transaction_Failed,
          payload: { error: data },
        });
        return Promise.reject();
      });
  };
};

const onAddingCardFailed = (error) => {
  return (dispatch) => {
    dispatch({
      type: paymentActionTypes.Adding_Failed,
      payload: {
        error: { data: [error?.message] || "Oops, Something went wrong" },
      },
    });
  };
};

const applyCoupon = (payload) => {
  return (dispatch) => {
    return httpClient
      .post(`${SERVER_APIS.CUPPON_CODE_URL}`, payload)
      .then(({ data }) => {
        dispatch({
          type: paymentActionTypes.Apply_Coppon_Success,
          payload: {
            applycopponcode: data,
            couponCode: payload?.couponCode,
            facilityType: payload?.facilityType,
          },
        });
        return Promise.resolve(data);
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: paymentActionTypes?.Apply_Coupon_Failure,
          payload: data,
        });
        return Promise.reject();
      });
  };
};

const resetCouponData = () => ({
  type: paymentActionTypes?.RESET_COUPON_DATA,
});

const onPPCSubscription = (data) => {
  return async (dispatch) => {
    try {
      const res = await httpClient.post(`${SERVER_APIS.PPC_Subs_Url}`, data);

      dispatch({
        type: paymentActionTypes.PPC_Subscribe,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: paymentActionTypes.PPC_Failed,
        payload: { error: error.response.data },
      });
      throw error;
    }
  };
};

const cancelPPC = (data) => {
  return (dispatch) => {
    return httpClient
      .post(SERVER_APIS.Cancel_PPC, data)
      .then((response) => {
        dispatch({
          type: paymentActionTypes.PPC_Cancel,
          payload: data,
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: paymentActionTypes.PPC_Cancel,
          payload: {},
        });
        return Promise.reject();
      });
  };
};

export const paymentActions = {
  addCard: onAddingCard,
  initCard,
  fetchPlanforIOP: onFetchPlanActionforIOP,
  getCards: onGetCards,
  removeCard: onRemoveCard,
  getTransactions,
  retryTransaction,
  onAddingCardFailed,
  disccountCouponapplayaction: applyCoupon,
  ppcSubscription: onPPCSubscription,
  cancelPPC,
  resetCouponData,
};
