import { httpClient } from "../utils";
import { SERVER_APIS } from "../constants";

export const phpActionType = {
  Php_Adding: "[Adding_Php] Action",
  Php_Add: "[Add_Php] Action",
  Php_Editing: "[Editing_Php] Action",
  Php_Edited: "[Edited_Php] Action",
  fetch_Php_ById_ActionType: "[GetById_Php] Action",
  fetch_Php_ById_Success: "[GetById_Php_Sucess] Action",
  fetch_Php_ById_Failure: "[GetById_Php_Failure] Action",
  SubscriptionPlan_Fetching: "[ Fetching_PhpPlan] Action",
  SubscriptionPlan_Fetched: "[ Fetched_PhpPlan] Action",
  PhpBasicPlan_Set: "[Set_PhpBasicPlan] Action",
  PhpProPlan_Set: "[Set_PhpProPlan] Action",
  PhpFacilityType_Set: "[Set_PhpFacilityType] Action",
  Php_Sub: "[sub_Php] Action",
  RESET_DATA: "[RESET_DATA] Action",
};

const onAddPhp = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: phpActionType.Php_Adding });

      const res = await httpClient.post(SERVER_APIS.Php, data);

      dispatch({
        type: phpActionType.Php_Add,
        payload: { php: res?.data },
      });

      return res;
    } catch (error) {
      console.error("Error occurred:", error);

      dispatch({
        type: phpActionType.Php_Add,
        payload: { php: {} },
      });

      throw error;
    }
  };
};

const fetchPhpDataById = (id) => {
  return (dispatch) => {
    dispatch({ type: phpActionType.fetch_Php_ById_ActionType });
    return httpClient
      .get(`${SERVER_APIS.Php}/${id}`)
      .then((res) => {
        dispatch({
          type: phpActionType.fetch_Php_ById_Success,
          payload: { phpdetailsById: res.data },
        });
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        dispatch({
          type: phpActionType.fetch_Php_ById_Failure,
          payload: { error },
        });
        return Promise.reject(error);
      });
  };
};

const onEditPhp = (id, data) => {
  return (dispatch) => {
    dispatch({ type: phpActionType.Php_Editing });
    return httpClient
      .patch(`${SERVER_APIS.Php}/${id}`, data)
      .then((res) => {
        dispatch({
          type: phpActionType.Php_Edited,
          payload: res.data,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: phpActionType.Php_Edited,
        });
        return Promise.reject(data);
      });
  };
};

// Subscription For PHP
const onFetchPhpPlan = () => {
  return (dispatch) => {
    dispatch({ type: phpActionType.SubscriptionPlan_Fetching });
    return httpClient
      .get(`${SERVER_APIS.Php_Plan}`)
      .then((response) => {
        dispatch({
          type: phpActionType.SubscriptionPlan_Fetched,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: phpActionType.SubscriptionPlan_Fetched,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

const setPhpBasicPlan = (data) => {
  return (dispatch) => {
    dispatch({
      type: phpActionType.PhpBasicPlan_Set,
      payload: { id: data?.id, price: data?.unit_amount },
    });
  };
};

const setPhpProPlan = (data) => {
  return (dispatch) => {
    dispatch({
      type: phpActionType.PhpProPlan_Set,
      payload: { id: data?.id, price: data?.unit_amount },
    });
  };
};

const setPhpFacilityType = (data) => {
  return (dispatch) => {
    dispatch({
      type: phpActionType.PhpFacilityType_Set,
      payload: {
        facilitytype: data?.facilityType,
        id: data?.id,
        name: data?.name,
      },
    });
  };
};

const isChangeTrue = (data) => {
  return (dispatch) => {
    dispatch({
      type: phpActionType.Php_Sub,
      payload: data,
    });
  };
};
const resetphpData = () => ({
  type: phpActionType?.RESET_DATA,
});

export const phpAction = {
  addPhp: onAddPhp,
  getPhpById: fetchPhpDataById,
  editPhp: onEditPhp,
  getPhpSubscriptionPlan: onFetchPhpPlan,
  setPhpProPlan,
  setPhpBasicPlan,
  setPhpFacilityType,
  isChangeTrue,
  resetphpData,
};
