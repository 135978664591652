import { detoxActionType } from "../actions";

const initialDetoxState = {
  addingDetox: false,
  fetching: false,
  editingDetox: false,
  detox: [],

  fetchingDetoxPlan: true,
  detoxPlan: [],
  priceId: null,
  priceAmount: null,
  facilityInfo: {},
  detoxdetial: null,
  isChange: false,
  subscriptionDetails: null,
};

export const detoxReducer = (state = initialDetoxState, action) => {
  switch (action.type) {
    case detoxActionType.Detox_Adding:
      return { ...state, addingDetox: true };
    case detoxActionType.Detox_Add:
      const { detox } = action.payload;
      return { ...state, addingDetox: false, detox: detox };

    case detoxActionType.Fetch_Subscription_Message:
      return { ...state, loading: true, error: null };

    case detoxActionType.Fetch_Subscription_Message_Success:
      const { subscriptionDetails } = action.payload;
      return { ...state, loading: false, subscriptionDetails };

    case detoxActionType.Fetch_Subscription_Message_Failed:
      const { error } = action.payload;
      return { ...state, loading: false, error };

    case detoxActionType.fetch_Detox_ById_ActionType:
      return { ...state, fetching: true };
    case detoxActionType.fetch_Detox_ById_Success:
      const { detoxDetail } = action.payload;
      return { ...state, fetching: false, detoxdetial: detoxDetail };
    case detoxActionType.fetch_Detox_ById_Failure:
      return { ...state, fetching: false, error: action.payload.error };

    case detoxActionType.Detox_Editing:
      return { ...state, editingDetox: true };
    case detoxActionType.Detox_Edited:
      return { ...state, editingDetox: false };

    case detoxActionType.SubscriptionPlan_Fetching:
      return { ...state, fetchingDetoxPlan: true };
    case detoxActionType.SubscriptionPlan_Fetched:
      return { ...state, fetchingDetoxPlan: false, detoxPlan: action.payload };

    case detoxActionType.DetoxBasicPlan_Set:
      return {
        ...state,
        priceId: action.payload?.id,
        priceAmount: action?.payload?.price,
      };
    case detoxActionType.DetoxProPlan_Set:
      return {
        ...state,
        priceId: action.payload?.id,
        priceAmount: action?.payload?.price,
      };
    case detoxActionType.DetoxFacilityType_Set:
      return {
        ...state,
        facilityInfo: {
          facilitytype: action?.payload?.facilitytype,
          id: action?.payload?.id,
          name: action?.payload?.name,
        },
      };

    case detoxActionType.Detox_Sub:
      return { ...state, isChange: action.payload };

      case detoxActionType.RESET_DATA:
        return { ...state, facilityInfo:{} };

    default:
      return state;
  }
};
