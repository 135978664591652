import { residentialActionType } from "../actions";

const initialResidentialState = {
  addingResidential: false,
  fetching: false,
  editingResidential:false,
  residential:[],
  fetchingResidentialPlan: true,
  residentialPlan:[],
  priceId:null,
  priceAmount:null,
  facilityInfo:{},
  isChange:false
};

export const residentialReducer = (state = initialResidentialState, action) => {
  switch (action.type) {
    case residentialActionType.Residential_Adding:
      return { ...state, addingResidential: true };
    case residentialActionType.Residential_Add:
      const { residential } = action.payload;
      return { ...state, addingResidential: false, residential:residential };

    case residentialActionType.fetch_Residential_ById_ActionType:
      return { ...state, fetching: true };
      
    case residentialActionType.fetch_Residential_ById_Success:
      const { residetial } = action.payload;
      return { ...state, addingResidential: false, residentialByID:residetial };

    case residentialActionType.fetch_Residential_ById_Failure:
      return { ...state, fetching: false, error: action.payload.error };

    case residentialActionType.Residential_Editing:
      return { ...state, editingResidential: true };
    case residentialActionType.Residential_Edited:
      return { ...state, editingResidential: false };

    case residentialActionType.set_Res_data_ActionType:
      return { ...state, fetching: true, data: null };
    case residentialActionType.set_Res_data_Success:
      return { ...state, fetching: false, data: action.payload };

    case residentialActionType.set_Res_data_Failure:
      return { ...state, fetching: false, error: action.payload.error };


    case residentialActionType.SubscriptionPlan_Fetching:
        return { ...state, fetchingResidentialPlan: true };
    case residentialActionType.SubscriptionPlan_Fetched:
        return { ...state, fetchingResidentialPlan: false,residentialPlan: action.payload };

    case residentialActionType.ResidentialBasicPlan_Set:
        return {...state, priceId:action.payload?.id, priceAmount:action?.payload?.price}
    case residentialActionType.ResidentialProPlan_Set:
        return {...state, priceId:action.payload?.id,priceAmount:action?.payload?.price }
    case residentialActionType.ResidentialFacilityType_Set:
        return {...state, facilityInfo:{facilitytype:action?.payload?.facilitytype, id:action?.payload?.id, name:action?.payload?.name}}
    
    case residentialActionType.Residential_Sub:
       return {...state, isChange:action.payload}
       case residentialActionType.RESET_DATA:
        return { ...state, facilityInfo:{} };
    default:
      return state;
  }
};
