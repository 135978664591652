import { httpClient } from "../utils";
import { SERVER_APIS } from "../constants";

export const addIopCenterActionType = {
  Iopcenter_Adding: "[Adding_ioCenter] Action",
  Iopcenter_Add: "[Add_Living] Action",
  Editing_iopCenter: "[Editing_Living] Action",
  Edited_iopCenter: "[Edited_Living] Action",
  fetch_IOPcneter_ById_ActionType: "[GetById_IopCenter] Action",
  fetch_IOPcneter_ById_Success: "[GetById_IopCenter_Sucess] Action",
  fetch_IOPcneter_ById_Failure: "[GetById_IopCenter_Failure] Action",

  SubscriptionPlan_Fetching: "[ Fetching_IopPlan] Action",
  SubscriptionPlan_Fetched: "[ Fetched_IopPlan] Action",
  IopBasicPlan_Set: "[Set_IopBasicPlan] Action",
  IopProPlan_Set: "[Set_IopProPlan] Action",
  IopFacilityType_Set: "[Set_IopFacilityType] Action",
  RESET_DATA: "[RESET_DATA] Action",
  Iop_Sub: "[sub_Iop] Action",
};

const onAddIopcenter = (iopcenters) => {
  iopcenters.phoneNumber = `${iopcenters.phoneNumber}`;

  return (dispatch) => {
    dispatch({ type: addIopCenterActionType.Iopcenter_Adding });
    return httpClient
      .post(SERVER_APIS.Addiop_Center, iopcenters)
      .then((res) => {
        dispatch({
          type: addIopCenterActionType.Iopcenter_Add,
          payload: { iopcenter: res.data },
        });
        return res;
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        dispatch({
          type: addIopCenterActionType.Iopcenter_Add,
          payload: { iopcenter: {} },
        });
        return Promise.reject(error);
      });
  };
};

const fetchIOPDataById = (IOPhouseId) => {
  return (dispatch) => {
    dispatch({ type: addIopCenterActionType.fetch_IOPcneter_ById_ActionType });
    return httpClient
      .get(`${SERVER_APIS.GetBy_ID_IOP_Center}/${IOPhouseId}`)
      .then((res) => {
        dispatch({
          type: addIopCenterActionType.fetch_IOPcneter_ById_Success,
          payload: { IOPhouseData: res.data },
        });
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        dispatch({
          type: addIopCenterActionType.fetch_IOPcneter_ById_Failure,
          payload: { error },
        });
        return Promise.reject(error);
      });
  };
};
const onEditIopCenter = (id, data) => {
  return (dispatch) => {
    dispatch({ type: addIopCenterActionType.Editing_iopCenter });
    return httpClient
      .patch(`${SERVER_APIS.Iop_URL}/${id}`, data)
      .then((res) => {
        dispatch({
          type: addIopCenterActionType.Edited_iopCenter,
          payload: res.data,
        });
        return Promise.resolve();
      })
      .catch(({ response: { data } }) => {
        dispatch({
          type: addIopCenterActionType.Edited_iopCenter,
        });
        return Promise.reject(data);
      });
  };
};

//subscription part
const onFetchIopPlan = () => {
  return (dispatch) => {
    dispatch({ type: addIopCenterActionType.SubscriptionPlan_Fetching });
    return httpClient
      .get(`${SERVER_APIS.Iop_Plan}`)
      .then((response) => {
        dispatch({
          type: addIopCenterActionType.SubscriptionPlan_Fetched,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch(() => {
        dispatch({
          type: addIopCenterActionType.SubscriptionPlan_Fetched,
          payload: [],
        });
        return Promise.reject();
      });
  };
};

const setIopBasicPlan = (data) => {
  return (dispatch) => {
    dispatch({
      type: addIopCenterActionType.IopBasicPlan_Set,

      payload: { id: data?.id, price: data?.unit_amount },
    });
  };
};

const setIopProPlan = (data) => {
  return (dispatch) => {
    dispatch({
      type: addIopCenterActionType.IopProPlan_Set,
      payload: { id: data?.id, price: data?.unit_amount },
    });
  };
};

const setIopFacilityType = (data) => {
  return (dispatch) => {
    dispatch({
      type: addIopCenterActionType.IopFacilityType_Set,
      payload: {
        facilitytype: data?.facilityType,
        id: data?.id,
        name: data?.name,
      },
    });
  };
};

const isChangeTrue = (data) => {
  return (dispatch) => {
    dispatch({
      type: addIopCenterActionType.Iop_Sub,
      payload: data,
    });
  };
};
const resetiopData = () => ({
  type: addIopCenterActionType?.RESET_DATA,
});

export const addiopcenterAction = {
  addIOPcenter: onAddIopcenter,
  editIopCenter: onEditIopCenter,
  getByIdIopcenterdeatials: fetchIOPDataById,
  getIopSubscriptionPlan: onFetchIopPlan,
  setIopProPlan,
  setIopBasicPlan,
  setIopFacilityType,
  isChangeTrue,
  resetiopData,
};
