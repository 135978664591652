import React, { Component } from "react";
import { SliderComponent } from "../../components/slider/slider.component";
import LivingDetailSliderCard from "../../components/cards/living-detail-slider.card";
import { connect } from "react-redux";

import { livingAction, homeAction, DashboardAction } from "../../actions";

import {
  CLIENT_RESTRICTION,
  CURFEW_DAYS,
  sobrietyRequirement,
  SobrietyRequirement,
  weekDayOrder,
} from "../../constants";
import {
  getImagesFromItem,
  getLocationFromItem,
  getPriceFromItem,
  getRatingsFromItem,
  Ucfirst,
} from "../../utils";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import ReviewDetailsComponent from "../../components/review/review-details.component";
import ReviewReplyForm from "../../components/review/review-reply-form";
import GoogleMapComponent from "../../components/map/google-map.component";
import TourBooking from "../tours/tour-booking";
import BackButtonComponent from "../../components/other/back-button.component";
import LivingReport from "./living-report";
import { Modal } from "react-bootstrap";
import noReviewImg from "../../assets/images/no-review-image.svg";
import ReactHelmet from "../../components/react-helmet/react-helmet";

import { bindActionCreators } from "redux";
import axios from "axios";
import { SERVER_APIS } from "../../constants";
import moment from "moment";
import RecommendedFacility from "../../components/home/recommendedFacility";
import SliderNextArrow from "../../components/slider/imageSliderNext";
import SliderPrevArrow from "../../components/slider/imageSliderPrev";

let pattern = /^((http|https|ftp):\/\/)/;
class LivingDetails extends Component {
  recommendedSlider = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // afterChange: (current) => this.setState.currentSlide(current),
    afterChange: (current) => {
      this.setState({ currentSlide: current });
    },
  };

  settings1 = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };
  errorPopUpStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    color: "#FF5F5F",
    alignItems: "center",
  };
  static defaultProps = {
    property: {},
  };
  static contextType = SpinnerContext;
  _isMounted = false;
  livingId = undefined;
  formValues = {
    options: [
      {
        key: "Type of property",
        value: "type",
        icon: "home-gray.svg",
      },
      {
        key: "Offers scholarships",
        value: "offersScholarships",
        icon: "people-24px.svg",
      },
      {
        key: "Offers payment plans",
        value: "offersPaymentPlans",
        icon: "people-24px.svg",
      },
      {
        key: "Monthly rent",
        value: "rentCost",
        icon: "dollar.svg",
        prefix: "$",
        dynamicKey: () => {
          return ` ${Ucfirst(this.state.property.paymentPeriod)} rent`;
        },
      },
      {
        key: "Serving",
        value: "serving",
        icon: "gender.svg",
      },
      {
        key: "Bedrooms",
        value: "bedroomQuantity",
        icon: "bedroom.svg",
      },
      {
        key: "Private bedrooms",
        value: "isOfferPrivateBedrooms",
        icon: "door-gray.svg",
      },
      {
        key: "Bathrooms",
        value: "bathroomQuantity",
        icon: "bathroom.svg",
      },
      {
        key: "Private bathrooms",
        value: "isOfferPrivateBathrooms",
        icon: "door-gray.svg",
      },
      {
        key: "Maximum Occupancy",
        value: "maximumOccupancy",
        icon: "people-24px.svg",
      },
      {
        key: "24 hour trained support staff",
        value: "haveTrainedSupportStaff",
        icon: "people-24px.svg",
      },
      {
        key: "Staff is required to have a year of sobriety or more",
        value: "staffRequiredHaveAYearOfSobriety",
        icon: "people-24px.svg",
      },
      {
        key: "Offers case management",
        value: "offersCaseManagement",
        icon: "people-24px.svg",
      },
      {
        key: "Must climb stairs",
        value: "mustClimbStairs",
        icon: "stairs.svg",
      },
      {
        key: "Parents with children Allowed",
        value: "isAllowedWithChildren",
        icon: "parking.svg",
      },
      {
        key: "Pet(s)",
        value: "isPetLiveInProperty",
        icon: "pets-24px.svg",
      },
      {
        key: "Provide transportation",
        value: "isProvideTransportation",
        icon: "car.svg",
      },

      {
        key: "Handicap Friendly",
        value: "isHandicapFriendly",
        icon: "wheelchair.svg",
      },
      {
        key: "Smoke friendly",
        value: "isSmokeFriendly",
        icon: "cigar.svg",
      },
      {
        key: "Private chef",
        value: "havePrivateChef",
        icon: "chef.svg",
      },
      {
        key: "No parking on property",
        value: "isNoParking",
        icon: "no-parking.svg",
      },
      {
        key: "Street parking only",
        value: "isStreetParkingOnly",
        icon: "parking.svg",
      },
      {
        key: "Surveillance or recording devices",
        value: "haveSurveillance",
        icon: "camera.svg",
      },
      {
        key: "Only accepts cash or checks",
        value: "isOnlyAcceptsCashOrChecks",
        icon: "purse.svg",
      },
      {
        key: "Accepts PayPal",
        value: "isAcceptsPaypal",
        icon: "paypal.svg",
      },
    ],
    requirements: [
      {
        key: "Sobriety Requirement",
        value: () => {
          const requirements = this.state.property.requirements;
          if (requirements.isSobrietyRequirement) {
            let result = sobrietyRequirement.find((item) => {
              return item.value == requirements.sobrietyRequirement;
            });
            return `${
              result ? result.label : requirements.sobrietyRequirement
            }`;
          }
          return "None";
        },
        icon: "clock.svg",
      },
      {
        key: "Gone through a program prior to sober living",
        value: "requirements.isGoneThroughAPriorProgram",
        icon: "calender.svg",
      },
      {
        key: "Completed detox",
        value: "requirements.isCompletedDetox",
        icon: "completed-box.svg",
      },
      {
        key: "Administer meds",
        value: "requirements.isAdministerMeds",
        icon: "capsule.svg",
      },
      {
        key: "MAT accepted house",
        value: "requirements.isYourHouseAcceptMAT",
        icon: "mat.png",
      },
      // {
      //   key: "Certified by the Sober Living Network",
      //   value: "requirements.isYouCertifiedByTheSoberLivingNetwork",
      //   icon: "certified.png",
      // },
    ],
    houseRules: [
      {
        key: "Daily Chores",
        value: "houseRules.dailyChores",
        icon: "chores.svg",
      },
      {
        key: "Weekly Chores",
        value: "houseRules.weeklyChores",
        icon: "vacuum cleaner.svg",
      },
      {
        key: "Random drug testing",
        value: "houseRules.randomDrugTesting",
        icon: "test-tube.svg",
      },
      {
        key: "Zero Tolerance Policy for drug tests",
        value: "houseRules.zeroToleranceForConfrontation",
        icon: "flask.svg",
      },
      {
        key: "Must be out of the house",
        value: () => {
          const houseRules = this.state.property.houseRules;
          if (houseRules["mustBeOutOfHouse"]) {
            return `from ${houseRules["mustBeOutOfHouseDateRange"]["from"]} to ${houseRules["mustBeOutOfHouseDateRange"]["to"]}`;
          }
          return "No";
        },
        icon: "clock.svg",
      },
      {
        key: "Working an honest program, we ask our residents to ensure a safe and sober environment by reporting any rule violations directly to staff",
        value: "houseRules.isReportingAllRulesViolations",
        icon: "info-24px.svg",
      },
      {
        key: "Do not disturb other residents",
        value: "houseRules.dndOtherResidents",
        icon: "do-not-disturb.svg",
      },
      {
        key: "12 step meetings",
        // value: 'houseRules.twelveStepMeetings',
        value: () => {
          const houseRules = this.state.property.houseRules;
          if (houseRules.twelveStepMeetings) {
            return `${houseRules.currentStep} per week`;
          }
          return "No";
        },
        icon: "12step.svg",
      },
      {
        key: "Mandatory House Meetings",
        value: "houseRules.mandatoryHouseMeetings",
        icon: "group.svg",
      },
      {
        key: "Weekly Check Ins",
        value: "houseRules.weeklyCheckIns",
        icon: "assignment_turned_in-24px.svg",
      },
      {
        key: "Personal goals",
        value: "houseRules.personalGoals",
        icon: "taget.svg",
      },
      {
        key: "Guest visiting hours",
        value: () => {
          const houseRules = this.state.property.houseRules;
          if (houseRules["haveGuestVisitingHours"]) {
            return `from ${houseRules["guestVisitingHours"]["from"]} to ${houseRules["guestVisitingHours"]["to"]}`;
          }
          return "No";
        },
        icon: "Group 27.svg",
      },
      {
        key: "Quiet hours from",
        value: () => {
          const houseRules = this.state.property.houseRules;
          if (houseRules["haveQuietHours"]) {
            return `from ${houseRules["quietHours"]["from"]} to ${houseRules["quietHours"]["to"]}`;
          }
          return "No";
        },
        icon: "mute.svg",
      },
      {
        key: "Overnight passes need to be approved",
        value: () => {
          const houseRules = this.state.property.houseRules;
          if (houseRules["overnightPassesApprovedInAdvance"]) {
            let daysInAdv = houseRules["overnightPassesApprovedInAdvance"];
            return `${daysInAdv} ${daysInAdv > 1 ? "Days" : "Day"} in advance`;
          }
          return "No";
        },
        icon: "profile.svg",
      },
      {
        key: "Client Restriction for the first",
        value: () => {
          return CLIENT_RESTRICTION[
            this.state.property.houseRules["clientRestrictionFor"]
          ];
        },
        icon: "password.svg",
      },
      {
        key: "Zero tolerance for stealing",
        value: "houseRules.zeroToleranceForStealing",
        icon: "steal.svg",
      },
      {
        key: "Zero tolerance for destroying property that does not belong to you",
        value: "houseRules.zeroToleranceForDestroyingProperty",
        icon: "destroyed-planet.svg",
      },
      {
        key: "Zero tolerance for sexual activity between residents",
        value: "houseRules.zeroToleranceForSexualActivity",
        icon: "heart.svg",
      },
      {
        key: "Zero tolerance for physical or verbal confrontation with staff or residents",
        value: "houseRules.zeroToleranceForConfrontation",
        icon: "fist.svg",
      },
      {
        key: "Any resident who is on prescribed medications must immediately notify house manager and all meds must be approved",
        value: "houseRules.mustNotifyOfPrescribedMeds",
        icon: "capsule.svg",
      },
      {
        key: "All medications taken must be taken as prescribed",
        value: "houseRules.allMedsMustBeTakenAsPrescribed",
        icon: "prescription.svg",
      },
      {
        key: "Meeting verification cards are due at house meetings",
        value: "houseRules.meetingCardsAreDueAtHouseMeetings",
        icon: "credit-card.svg",
      },
      {
        key: "Residents must have a sponsor while actively working the 12 steps",
        value: "houseRules.mustHaveASponsorFor12Steps",
        icon: "profile.svg",
      },
      {
        key: "Residents rooms must be clean at all times",
        value: "houseRules.residentsRoomsMustBeClean",
        icon: "clean.svg",
      },
      {
        key: "No loitering in front of the property",
        value: "houseRules.noLiteringInFrontOfTheProperty",
        icon: "walk.svg",
      },
      {
        key: "Residents that have unusual job schedules must be approved by staff",
        value: "houseRules.unusualJobSchedulesMustBeApproved",
        icon: "profile.svg",
      },
      {
        key: "Make payment ont the first of each month",
        value: "houseRules.payRentOnTheFirstOfEachMonth",
        icon: "profile.svg",
      },
      {
        key: "Keep all shared spaces clean at all times",
        value: "houseRules.allSharedSpacesMustBeClean",
        icon: "profile.svg",
      },
      {
        key: "No illegal activity may occur on property",
        value: "houseRules.noIllegalActivity",
        icon: "profile.svg",
      },
      {
        key: "Be accountable and check in with house manager",
        value: "houseRules.beAccountable",
        icon: "profile.svg",
      },
      {
        key: "Abide by local rules and regulations for Covid 19",
        value: "houseRules.abidingLocalCovidRules",
        icon: "profile.svg",
      },
      {
        key: "Breathalyzed Nightly",
        value: "houseRules.breathalyzedNightly",
        icon: "breatheanalyzed.png",
      },
    ],
  };
  errorPopUpStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    color: "#FF5F5F",
    alignItems: "center",
  };

  constructor(props) {
    super(props);
    const storedData = localStorage.getItem("taste_recovery:auth");
    const {
      match: { params },
    } = this.props;
    let id = params.id.split("&id=")[1] || params.id;
    this.livingId = id;
    let property = {};
    if (this.props.location.state) {
      property = this.props.location.state?.property;
    }
    this.state = {
      userDetails: storedData ? JSON.parse(storedData) : null,
      isDataFetched: false,
      property,
      reviews: [],
      tourModalShow: false,
      reviewId: null,
      isError: false,
      SubscriptionOpt: "Opt-Out",
      errorMessage: "",
      currentSlide: 0,
      isShowPlan: false,
      isShowCancel: false,
      subscriptionId: "",
      livingIdForSubscribe: "",
      showErrorPopup: false,
      showSuccessPopup: false,
      popUpMessage: "",
      errors: null,
      showUpgradeModel: false,
      planPrice: "",
      amount: "",
      interval: "",
      prevSubscriptionStatus: "",
      pageReload: false,
      websiteURL: null,
      showCallModel: false,
      showErrorPopupMeassage: false,
      recommendedFacilities: [],
    };
    this.fetchRecommendedHouse = this.fetchRecommendedHouse.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.context.setStickyHeader(false);
    this.context.setStickyFooter(false);
    this.props.fetchPlan();
    this.onMarkAsViewed();

    this.handlePpcClick();
    this.fetchDetails();
    // if(this.livingId){
    this.fetchRecommendedHouse();
    // }
    this.fetchRecommendedHouse();
    // }

    const storedData = localStorage.getItem("taste_recovery:auth");
    if (storedData) {
      this.setState({ userDetails: JSON.parse(storedData) });
    }
    window.addEventListener("storage", this.handleStorageChange);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleStorageChange);
  }

  handleStorageChange = (event) => {
    if (event.key === "taste_recovery:auth") {
      this.fetchDetails();
      this.setState({ userDetails: JSON.parse(event.newValue) });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { match } = this.props;
    if (prevProps !== this.props && !this.props.isFetchingLiving) {
      this.setState({ ...this.props.properties.get(this.livingId) });
    }

    const currentId = match.params.id.split("&id=")[1];

    // Extract the previous ID from the URL
    const prevId = prevProps.match.params.id.split("&id=")[1];

    if (prevId !== currentId) {
      //this.fetchDetails();
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  cancelSubscription = () => {
    this.context.showLoader("");
    const value = {
      subscriptionId: this.state.subscriptionId,
      userId: this.props.user?.id,
    };
    var authOptions = {
      method: "post",
      url: `${SERVER_APIS.Living_URL}/${this.state.livingIdForSubscribe}/cancel/subscription`,
      data: value,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(authOptions).then(async (res) => {
      try {
        this.context.showLoader("");
        // this.context.showLoader(`${res?.data?.message}`);

        this.setState({
          popUpMessage: res.data.message,
          isShowCancel: false,
          showSuccessPopup: false,
          showErrorPopupMeassage: true,
        });
      } catch (e) {
        this.context.hideLoader();
        setTimeout(() => {
          this.setState({
            // showErrorPopup: false,
            isShowCancel: false,
            // popUpMessage: e.error
          });
        }, 1000);
      }

      setTimeout(() => {
        this.context.hideLoader();
        this.setState({
          popUpMessage: "",
          isShowCancel: false,
          showSuccessPopup: false,
          showErrorPopupMeassage: false,
        });
      }, 6000);
    });
    //this.context.hideLoader();
    this.setState(
      {
        pageReload: true,
      },
      () => {
        this.loadPage();
      }
    );
  };

  fetchRecommendedHouse = async () => {
    try {
      const response = await axios.get(
        `${SERVER_APIS.recommendedFacilityListById}/${this.livingId}`
      );
      this.setState({
        recommendedFacilities: response?.data?.data,
      });
    } catch (error) {
      console.error("Error fetching recommended house data:", error);
    }
  };

  handelCreteClick = (data) => {
    let typeOfImpression = [];

    switch (data) {
      case "phone":
        typeOfImpression = ["phone"];
        break;
      case "website":
        typeOfImpression = ["website"];
        break;
      case "facility":
        typeOfImpression = ["facility"];
        break;
      case "chat":
        typeOfImpression = ["chat"];
        break;
      default:
        break;
    }

    this.props.CreteClikforDashbaord({
      facilityId: this.livingId,
      typeOfImpression: typeOfImpression,
    });
  };

  upgradePlan = () => {
    this.setState({
      subscriptionId: this.state.property.subscriptionDetail.subscriptionId,
      showUpgradeModel: true,
    });
  };

  setPlanstate = () => {
    this.setState({
      planPrice: this.props.subscribePlans[0].id,
      amount: this.props.subscribePlans[0].unit_amount,
      interval: this.props.subscribePlans[0].recurring.interval,
    });
  };

  loadPage = () => {
    this.context.showLoader("Get Sober Living ...");
    setTimeout(() => {
      window.location.reload(true);
    }, 2000);
  };

  handleRedirection = () => {
    const { property } = this.state;

    this.props.setDataforRedirection({
      name: property?.name,
      url: window.location?.pathname,
    });

    this.props.history.push("/dashboard");
  };

  render() {
    const userID = this.props?.user ? this.props?.user : null;
    const {
      showSuccessPopup,
      errors,
      popUpMessage,
      showErrorPopup,
      showErrorPopupMeassage,
    } = this.state;

    const Options_UI = ({ items: items, ...rest }) => {
      const yesItems = items.filter(
        (item) => this.getValueOfItem(item.value) !== "No"
      );

      return (
        <ul className="feature-list mt-3">
          {" "}
          {yesItems.map((item, index) => {
            return (
              <li key={index}>
                <img src={require(`../../assets/images/${item.icon}`)} alt="" />
                <span className="feature-name">
                  {item.hasOwnProperty("dynamicKey")
                    ? item.dynamicKey()
                    : item.key}
                  {this.getValueOfItem(item.value) !== "Yes" && (
                    <>
                      :
                      <span className="feature-value">
                        {" "}
                        {(item.prefix ? item.prefix : "") +
                          this.getValueOfItem(item.value)}{" "}
                      </span>
                    </>
                  )}
                </span>
              </li>
            );
          })}
        </ul>
      );
    };

    const Curfew_UI = (props) => {
      const houseRules = this.state.property.houseRules;
      let keys = Object.keys(houseRules["curfewTime"]);
      const curfewTimes = houseRules["curfewTime"];
      let hasDaily = false;
      keys.sort((next, prev) => {
        return weekDayOrder[next] - weekDayOrder[prev];
      });
      if (keys.indexOf(CURFEW_DAYS.daily) >= 0) {
        keys.splice(keys.indexOf(CURFEW_DAYS.daily), 1);
        hasDaily =
          curfewTimes[CURFEW_DAYS.daily] !== null &&
          curfewTimes[CURFEW_DAYS.daily] !== "Different hours per day";
      }
      return (
        <ul>
          <li className={props.isMobile && hasDaily ? "border-0" : ""}>
            {props.isMobile ? (
              <span>
                Curfew: {hasDaily ? curfewTimes[CURFEW_DAYS.daily] : ""}
              </span>
            ) : (
              <span>Curfew: </span>
            )}
          </li>
          {props.isMobile &&
            !hasDaily &&
            keys.map((key, index) => {
              return (
                <li key={index}>
                  <span className="feature-name">{CURFEW_DAYS[key]}:</span>
                  <span className="feature-value">
                    {hasDaily
                      ? curfewTimes[CURFEW_DAYS.daily]
                      : curfewTimes[key].toUpperCase()}
                  </span>
                </li>
              );
            })}
          {!props.isMobile &&
            keys.map((key, index) => {
              return (
                <li key={index}>
                  <span className="feature-name">{CURFEW_DAYS[key]}:</span>
                  <span className="feature-value">
                    {hasDaily
                      ? curfewTimes[CURFEW_DAYS.daily]
                      : curfewTimes[key].toUpperCase()}
                  </span>
                </li>
              );
            })}
        </ul>
      );
    };

    const metaTitle = (propertyDetail) => {
      const { name, geocodingResult } = propertyDetail;
      const { cityName, stateLong } = geocodingResult || {};
      return `${name} - ${cityName},${stateLong} - Taste Recovery`;
    };

    const formatPhoneNumber = (phoneNumber) => {
      const phoneNumberString = phoneNumber.toString();
      const cleaned = phoneNumberString.replace(/\D/g, "");
      const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

      return formatted;
    };

    const formattedNumber =
      this.state.property.phoneNumber != null
        ? formatPhoneNumber(this.state.property.phoneNumber)
        : null;

    return (
      <>
        {" "}
        {showErrorPopup && (
          <Modal
            centered
            show={true}
            style={this.errorPopUpStyle}
            className="spinner-taste"
            onHide={() => {}}
          >
            <img
              src={require("../../assets/images/cross-cancel.png")}
              width="50"
              heigth="50"
              alt="Error!"
            />
            <span className="loading-msg">{popUpMessage}</span>
          </Modal>
        )}
        {showErrorPopupMeassage && (
          <Modal
            centered
            show={true}
            style={this.errorPopUpStyle}
            className="spinner-taste"
            onHide={() => {}}
          >
            <span className="loading-msg mt-0">{popUpMessage}</span>
          </Modal>
        )}
        {this.state.isError && (
          <Modal
            centered
            show={this.state.isError}
            style={this.errorPopUpStyle}
            className="spinner-taste"
            onHide={() => {}}
          >
            <img
              src={require("../../assets/images/cross-cancel.png")}
              width="50"
              heigth="50"
              alt="Error!"
            />
            <span className="loading-msg">{this.state.errorMessage}</span>
            <button
              type="submit"
              className="btn btn-primary radius-20 btn-sm-width my-2"
              onClick={() => {
                this.props.user
                  ? this.props.history.goBack()
                  : this.props.history.push("/");
              }}
            >
              {this.props.user ? "Go Back" : "Go Home"}
            </button>
          </Modal>
        )}
        {Object.keys(this.state.property).length > 0 && (
          <div>
            <ReactHelmet
              title={metaTitle(this.state.property)}
              description={this.state.property?.about.slice(0, 160)}
            />

            <section className="inner-hero pt-0">
              <BackButtonComponent className="back-property" />
              <SliderComponent
                component={LivingDetailSliderCard}
                items={this.getImages()}
                settings={this.getSliderSettings(
                  this.getImages().length >= 3 ? 3 : this.getImages().length
                )}
              />
            </section>

            <section className="property-top">
              <div className="container">
                <div className="property-wrp">
                  <div
                    className={
                      this.state.property.createdBy !== this.props.user?.id &&
                      this.state.property.isSubscribed === true
                        ? "property-top-left detailpagetitle seekerpage"
                        : "property-top-left detailpagetitle"
                    }
                  >
                    <h3 className="cursor-pointer">
                      {this.state.property.name}
                      {this.state.property.isMyFavorite ? (
                        <span onClick={() => this.removeBookmarkLiving()}>
                          <img
                            src={require("../../assets/images/bookmark-select.svg")}
                            alt=""
                            width="30"
                            height="30"
                          />
                        </span>
                      ) : (
                        <span onClick={() => this.bookmarkLiving()}>
                          <img
                            src={require("../../assets/images/bookmark_border-24px copy.svg")}
                            alt=""
                          />
                        </span>
                      )}
                    </h3>
                    <div className="loc-n-status">
                      <span
                        className="location"
                        style={{ display: "contents" }}
                      >
                        <img
                          src={require("../../assets/images/ic_pin_small.svg")}
                          alt=""
                        />
                        {getLocationFromItem(this.state.property)}
                      </span>
                      <span
                        className={`status ml-2 ${
                          this.state.property["isAvailableCurrently"]
                            ? "available"
                            : "booked"
                        }`}
                      >
                        {this.state.property["isAvailableCurrently"]
                          ? " Available Now"
                          : ""}
                      </span>
                    </div>
                    <div className="rate">
                      {this.state.property.isSubscribed === true && (
                        <span className="rating-recommended">
                          {/* <img src={require('../../assets/images/star-24px.svg')} alt="" /> */}
                          <svg
                            id="star-24px"
                            xmlns="http://www.w3.org/2000/svg"
                            width={17}
                            height={17}
                            viewBox="0 0 17 17"
                          >
                            <path id="Path" d="M0,0H17V17H0Z" fill="none" />
                            <path
                              id="Path-2"
                              data-name="Path"
                              d="M7.083,10.816l4.378,2.642L10.3,8.479l3.868-3.35L9.074,4.7,7.083,0,5.093,4.7,0,5.128l3.868,3.35-1.162,4.98Z"
                              transform="translate(1.417 1.417)"
                              fill="#ff5f5f"
                            />
                          </svg>
                          &nbsp;{" "}
                          {/* {getRatingsFromItem(
                            Number(this.state.property?.rating?.value)
                          )} */}
                          {this.state.property?.rating?.value?.toFixed(1)}
                        </span>
                      )}
                      <span className="ml-2">
                        ${getPriceFromItem(this.state.property)}
                      </span>
                    </div>
                  </div>

                  <div
                    className="property-top-right w-100"
                    style={{ display: "block" }}
                  >
                    <div className="property-btns flex-wrap mr-0">
                      {this.state.property.isSubscribed === true &&
                      this.state.property.phoneNumber ? (
                        this.state.property.createdBy !==
                        this.props.user?.id ? (
                          <a
                            role="button"
                            onClick={() => this.handelCreteClick("phone")}
                            // onClick={() => {
                            //   if (
                            //     this.state.property.createdBy !==
                            //     this.props.user?.id
                            //   ) {
                            //     this.handleCreteClick("phone");
                            //     window.location.href = `tel:${this.state.property.phoneNumber}`;
                            //   } else {
                            //     this.handleCreteClick("phone");
                            //   }
                            // }}
                            className="btn btn-primary radius-20 btn-primary mr-3 detailbtn btn-minwidth"
                            href={`tel:${this.state.property.phoneNumber}`}
                          >
                            <img
                              src={require("../../assets/images/phone.svg")}
                              alt=""
                            />
                            <b>{formattedNumber}</b>
                          </a>
                        ) : (
                          <span
                            role="button"
                            onClick={() => this.handelCreteClick("phone")}
                            className="btn btn-primary radius-20 btn-primary mr-3 detailbtn btn-minwidth"
                            //  href={`tel:${this.state.property.phoneNumber}`}
                          >
                            <img
                              src={require("../../assets/images/phone.svg")}
                              alt=""
                            />
                            <b>{formattedNumber}</b>
                          </span>
                        )
                      ) : (
                        ""
                      )}

                      {this.state.property.isSubscribed === true &&
                      !!this.state.property.webUrl ? (
                        <a
                          role="button"
                          onClick={() => this.handelCreteClick("website")}
                          className="btn btn-primary radius-20 btn-primary mr-3 mr-lg-0 mr-xl-3 detailbtn btn-minwidth"
                          href={
                            !pattern.test(this.state.property.webUrl)
                              ? "https://" + this.state.property.webUrl
                              : this.state.property.webUrl
                          }
                          target="_blank"
                        >
                          <img
                            src={require("../../assets/images/arrowup-white.svg")}
                            alt=""
                          />
                          <b>Visit Website</b>
                        </a>
                      ) : (
                        ""
                      )}

                      {this.state.property.isSubscribed === true &&
                      this.state.property?.createdBy === this.props.user?.id ? (
                        <a
                          onClick={() => this.handleRedirection()}
                          role="button"
                          className="btn btn-primary radius-20 btn-primary mr-3 detailbtn btn-minwidth"
                        >
                          <img
                            src={require("../../assets/images/dashboardwithoutbg.svg")}
                            alt=""
                          />
                          <b> View Dashboard</b>
                        </a>
                      ) : (
                        ""
                      )}

                      {this.state.property.createdBy !== this.props.user?.id &&
                        this.state.property.isSubscribed === true && (
                          <span onClick={() => this.handelCreteClick("chat")}>
                            <a
                              role="button"
                              onClick={() => this.contactHome()}
                              className="btn btn-primary radius-20 contact-home-btn btn-primary mr-3 mr-lg-0 mr-xl-3 detailbtn btn-minwidth"
                            >
                              <img
                                src={require("../../assets/images/envelope-white.svg")}
                                alt=""
                              />
                              <b> Message Home</b>
                            </a>
                          </span>
                        )}

                      {this.state.property["isAvailableForTours"] &&
                        !this.props.isHost &&
                        this.state.property.isSubscribed === true && (
                          <TourBooking property={this.state.property} />
                        )}
                      {this.props.isHost &&
                        this.state.property?.createdBy ===
                          this.props.user?.id && (
                          <button
                            onClick={() => {
                              this.props.history.push(
                                `/living/edit-living/${this.state.property.id}`
                              );
                            }}
                            className="btn btn-primary radius-20 btn-primary text-center detailbtn btn-minwidth web-btn mr-0"
                          >
                            <img
                              className="mr-1"
                              src={require("../../assets/images/edit-living.svg")}
                              alt=""
                            />
                            <b>Edit</b>
                          </button>
                        )}
                      {this.props.isHost &&
                        this.state.property?.createdBy ===
                          this.props.user?.id && (
                          <button
                            onClick={() => {
                              this.props.history.push(
                                `/living/edit-living/${this.state.property.id}`
                              );
                            }}
                            className="btn btn-primary radius-20 btn-primary text-center detailbtn btn-minwidth resp-btn"
                          >
                            <img
                              className="mr-1"
                              src={require("../../assets/images/edit-living.svg")}
                              alt=""
                            />
                            <b>Edit</b>
                          </button>
                        )}
                    </div>
                    <div className="livingReport">
                      {" "}
                      {this.props.user && (
                        <LivingReport soberLivingId={this.state.property.id} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {this.state.property?.createdBy === this.props.user?.id &&
            this.props.isHost ? (
              <section className="active-plan">
                <div className="container">
                  <div className="plan-wrp">
                    <div className="plan-wrp">
                      <img
                        src={require("../../assets/images/email-marketing.svg")}
                        className="mr-3"
                        alt=""
                      />
                      <div className="plan-content">
                        <span>Active Plan</span>
                        {this.state.property.isSubscribed === false ? (
                          <h3>Basic</h3>
                        ) : (
                          <>
                            <h3>
                              $
                              {this.state.property.subscriptionDetail &&
                                this.state.property.subscriptionDetail.price}
                              <span>
                                {this.state.property.subscriptionDetail &&
                                  this.state.property.subscriptionDetail
                                    .interval}{" "}
                              </span>
                            </h3>
                            {this.state.property.subscriptionDetail
                              ?.isCanceled ? (
                              ""
                            ) : (
                              <span>
                                Renewal Date :{" "}
                                {this.state.property.subscriptionDetail &&
                                  moment(
                                    this.state.property.subscriptionDetail
                                      .currentPeriodEnd
                                  ).format("MM-DD-yyyy")}{" "}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {
                      // due  to subcription interval key is now month that reason  put or condition

                      this.state.property?.subscriptionDetail?.interval ===
                        "monthly" ||
                      this.state.property?.subscriptionDetail?.interval ===
                        "month" ? (
                        <div className="upgrade-btn">
                          <button
                            className="btn btn-white radius-20"
                            onClick={() => this.upgradePlan()}
                          >
                            Upgrade
                          </button>
                        </div>
                      ) : (
                        ""
                      )
                    }
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}

            <Modal
              centered
              show={this.state.showUpgradeModel}
              className="upgrade-plan"
            >
              <div className="upgrade-plan-content">
                <h3>Upgrade Your Plan</h3>
                <span className="loading-msg">
                  Your monthly subscription will end after this month and your
                  yearly subscription will start on (
                  {this.state.property.subscriptionDetail &&
                    moment(
                      this.state.property.subscriptionDetail?.currentPeriodEnd
                    ).format("MM-DD-yyyy")}
                  ).
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="modal-btn"
                  onClick={() => {
                    this.setState({
                      showUpgradeModel: false,
                    });
                  }}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="modal-btn confirm-btn"
                  onClick={() => {
                    this.setPlanstate();
                    let subscriptionPlans = this.props.subscribePlans.filter(
                      (data) =>
                        data?.recurring?.interval !=
                        this.state.property.subscriptionDetail?.interval
                    );
                    this.setState({
                      isShowPlan: true,
                      showUpgradeModel: false,
                      planPrice: subscriptionPlans[0].id,
                      amount: subscriptionPlans[0].unit_amount,
                      interval: subscriptionPlans[0].recurring.interval,
                    });
                  }}
                >
                  CONFIRM
                </button>
              </div>
            </Modal>

            <Modal
              centered
              show={this.state.isShowCancel}
              onHide={() => {}}
              className="upgrade-plan"
            >
              <div className="upgrade-plan-content">
                <h3 style={{ textTransform: "inherit" }}>
                  Are you sure, you want to cancel your PRO subscription?{" "}
                </h3>
                <span className="loading-msg">
                  Your benefits like chat, customer review, website link and
                  phone number will be disabled from your next renewal date.
                  Services will continue until the current subscription term
                  ends.
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="modal-btn"
                  onClick={() => {
                    this.setState({
                      isShowCancel: false,
                    });
                  }}
                >
                  NO
                </button>
                <button
                  type="submit"
                  className="modal-btn confirm-btn"
                  onClick={() => this.cancelSubscription()}
                >
                  YES
                </button>
              </div>
            </Modal>

            <Modal
              show={this.state.isShowPlan}
              onHide={() => {
                this.setState({
                  isShowPlan: false,
                });
              }}
              className="pro-plan-modal upgrade-plan"
              backdrop="static"
            >
              <div className="pro-plan-content upgrade-plan-content">
                <div className="modal-header">
                  <h3>Pro Plan</h3>
                  <img
                    src={require("../../assets/images/cancle.svg")}
                    className="mr-3"
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({
                        isShowPlan: false,
                        SubscriptionOpt: "Opt-Out",
                      });
                    }}
                  />
                </div>
                <div className="plan-content">
                  <div className="plan-info" style={{ textAlign: "justify" }}>
                    Our subscription pro plan includes the usage of the chat
                    feature, customer reviews, a website link on your home page
                    and a phone number listing for each house that has been
                    upgraded as Pro.
                    <br />
                    <br /> Subscriptions are automatically renewed and billed on
                    a monthly or annual basis until you cancel. Annual
                    subscriptions are paid up front and are based on a one-year
                    commitment. You may change or cancel your subscription at
                    any time. All subscription payments are non-refundable.
                    Taste Recovery terms apply.
                    <br />
                    <br /> For questions on plans, pricing, or billing, please
                    visit our Contact Us page.
                  </div>
                  <form>
                    {this.props.subscribePlans.map((data, index) => {
                      if (this.state.property.subscriptionDetail === null) {
                        return (
                          <div className="subscribePlan" key={index}>
                            <input
                              type="radio"
                              id={data?.id}
                              value={data?.recurring.interval}
                              checked={this.state.planPrice === data?.id}
                              name="subscription plan"
                              onChange={() => {
                                this.setState({
                                  planPrice: data?.id,
                                  interval: data?.recurring?.interval,
                                  amount: data?.unit_amount,
                                });
                              }}
                            />
                            <label htmlFor="price">
                              {data?.recurring.interval
                                .charAt(0)
                                .toUpperCase() +
                                data?.recurring?.interval?.slice(1)}{" "}
                            </label>
                          </div>
                        );
                      } else {
                        if (
                          this.state.property.subscriptionDetail?.interval !=
                          data?.recurring?.interval
                        ) {
                          return (
                            <div className="subscribePlan">
                              <input
                                type="radio"
                                id={data?.id}
                                value={data?.recurring?.interval}
                                checked={this.state.planPrice === data?.id}
                                name="subscription plan"
                                onChange={() => {
                                  this.setState({
                                    planPrice: data?.id,
                                    interval: data?.recurring?.interval,
                                    amount: data?.unit_amount,
                                  });
                                }}
                              />
                              <label htmlFor="price">
                                {data?.recurring?.interval
                                  .charAt(0)
                                  .toUpperCase() +
                                  data?.recurring?.interval?.slice(1)}
                              </label>
                            </div>
                          );
                        }
                      }
                    })}
                  </form>
                  <span className="loading-msg" style={{ marginTop: "10px" }}>
                    {/* <a href="/contact-support" style={{ textDecoration: 'none', color: '#333333' }}><b>"Contact US"</b></a>  */}
                  </span>
                  <div className="plan-price-content">
                    <span>You will be charged</span>
                    <h3>
                      ${this.state.amount}
                      <span>{this.state.interval}</span>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary radius-20 btn-outline"
                  onClick={() => {
                    this.setState({
                      isShowPlan: false,
                      SubscriptionOpt: "Opt-Out",
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary radius-20 btn-primary"
                  onClick={() => {
                    if (this.state.property?.subscriptionDetail === null) {
                      this.context.setsubscribePlanId(this.state.planPrice);
                      this.context.setLivingId(this.livingId);
                      this.context.setselectedInterval(
                        this.state.property.name
                      );
                      this.context.setselectedAmount(this.state.amount);
                      this.props.history.push("/payment-options");
                    } else {
                      this.context.showLoader(
                        "Upgrading subscription plan ..."
                      );
                      const value = {
                        subscriptionId:
                          this.state.property?.subscriptionDetail
                            ?.subscriptionId,
                        priceId: this.state.planPrice,
                        userId: this.props.user?.id,
                      };
                      var authOptions = {
                        method: "post",
                        url: `${SERVER_APIS.Living_URL}/${this.livingId}/upgrade/subscription`,
                        data: value,
                        headers: {
                          "Content-Type": "application/json",
                        },
                      };
                      axios(authOptions)
                        .then(async (res) => {
                          this.context.hideLoader();
                          window.location.reload();
                        })
                        .catch((error) => {
                          alert(error);
                        });
                    }
                  }}
                >
                  Pay Subscription
                </button>
              </div>
            </Modal>

            {/* {showSuccessPopup && <Toast
              show={true}
              autohide
              className="subscription-toast upgrade-plan">
              <Toast.Body>
                <p><img src={require('../../assets/images/tick_check_mark.svg')} className="tick_mark-icon" alt="" /> {popUpMessage}
                  <img src={require('../../assets/images/cancle.svg')} alt="" onClick={() => {
                    this.setState({
                      showSuccessPopup: false
                    })
                  }} style={{ cursor: 'pointer' }} /></p>
                <div className="modal-footer">
                  <button type="submit" className="modal-btn"
                    onClick={() => {
                      this.setState({
                        showSuccessPopup: false
                      })
                    }}>Cancel</button>
                  <button type="submit" className="modal-btn confirm-btn"
                    onClick={() => {
                      this.setState({
                        showSuccessPopup: false
                      })
                    }}>Ok</button>
                </div>
              </Toast.Body>
            </Toast>
            } */}

            {this.state.property.createdBy === this.props.user?.id &&
            this.props.isHost ? (
              <section className="subscription-plan">
                <div className="container">
                  <div className="subscription-wrp">
                    <div className="subscription-title">
                      <h3
                        className="detailtitle"
                        style={{
                          marginBottom:
                            this.state.property.isSubscribed === false
                              ? "9%"
                              : "",
                        }}
                      >
                        SUBSCRIPTION
                      </h3>
                      <form>
                        <div className="form-group dflex-form no-border">
                          <label className="tooltip">
                            {this.state.property.isSubscribed === false &&
                            this.state.SubscriptionOpt === "Opt-Out" ? (
                              <span className="tooltiptext">
                                Upgrade from free plan to paid subscription for
                                maximum benefits
                              </span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                        <div className="togglediv d-flex flex-wrap align-items-center">
                          <div className="pricing-toggle">
                            <div className="toggle-button-container d-flex align-items-center">
                              <input
                                type="radio"
                                id="pricing-toggle-monthly"
                                name="pricing"
                                value="Monthly"
                                checked={
                                  this.state.property.isSubscribed === false &&
                                  this.state.SubscriptionOpt === "Opt-Out"
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="radio-button"
                                htmlFor="pricing-toggle-monthly"
                                onClick={() => {
                                  this.setPlanstate();
                                  if (
                                    this.state.property.isSubscribed === true
                                  ) {
                                    this.setState({
                                      isShowCancel: true,
                                      subscriptionId:
                                        this.state.property.subscriptionDetail
                                          .subscriptionId,
                                      livingIdForSubscribe: this.livingId,
                                    });
                                  }
                                }}
                              >
                                Basic
                              </label>
                            </div>
                            <div className="toggle-button-container d-flex align-items-center">
                              <input
                                type="radio"
                                id="pricing-toggle-annually"
                                name="pricing"
                                value="Annually"
                                checked={
                                  this.state.property.isSubscribed === false &&
                                  this.state.SubscriptionOpt === "Opt-Out"
                                    ? false
                                    : true
                                }
                              />
                              <label
                                className="radio-button"
                                htmlFor="pricing-toggle-annually"
                                onClick={() => {
                                  this.setPlanstate();
                                  if (
                                    this.state.property.isSubscribed === false
                                  ) {
                                    this.setState({
                                      isShowPlan: true,
                                      SubscriptionOpt:
                                        this.state.SubscriptionOpt === "Opt-Out"
                                          ? "Opt-In"
                                          : "Opt-Out",
                                    });
                                  }
                                  // else {
                                  //   this.setState({
                                  //     isShowCancel: true,
                                  //     subscriptionId: this.state.property.subscriptionDetail.subscriptionId,
                                  //     livingIdForSubscribe: this.livingId
                                  //   })
                                  // }
                                }}
                              >
                                Pro
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="plan-list">
                      {this.state.property.isSubscribed === false ? (
                        <div className="plan-card">
                          <h3>Basic Plan</h3>
                          <div className="plan-info">
                            <ul>
                              <li>House Rules</li>
                              <li>Curfew</li>
                              <li>Amenities</li>
                              <li>Requirements</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="plan-card">
                        <h3>Pro Plan</h3>
                        <div className="plan-info">
                          <ul>
                            <li>House Rules</li>
                            <li>Curfew</li>
                            <li>Amenities</li>
                            <li>Requirements</li>
                            <li>Customer Reviews</li>
                          </ul>
                          <ul>
                            <li>Website Listed</li>
                            <li>Phone Number Listed</li>
                            <li>
                              Chat with potential clients through the <br />
                              platform
                            </li>
                            <li>Scheduling House Tours</li>
                            <li>Recommend more facilities</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              ""
            )}

            <section className="property-detail">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="about">
                      <h4 className="sub-title"> About</h4>
                      <p>{this.state.property.about}</p>
                    </div>
                    <div className="abilable-options">
                      <h4 className="sub-title">Amenities</h4>
                      <div className="available-opt-icons">
                        {this.state.property.options &&
                          this.state.property.options.map((item, index) => {
                            item = item.replace("/", "");
                            item = item.replace(/(?=[A-Z])/, "-").toLowerCase();
                            let optionsImage;
                            try {
                              optionsImage = require(`../../assets/images/${item}-light-gray.svg`);
                            } catch {
                              optionsImage = require("../../assets/images/default.png");
                            }
                            return (
                              <img key={index} src={optionsImage} alt=" " />
                            );
                          })}
                      </div>
                    </div>

                    <div className="map-view-wrp mb-4">
                      <div className="location-wrp mt-3 border-0 pb-md-0">
                        <h4 className="sub-title mb-1">Location</h4>
                        <span className="location">
                          <img
                            src={require("../../assets/images/ic_pin_small.svg")}
                            alt=""
                          />
                          {getLocationFromItem(this.state.property)}
                        </span>
                      </div>

                      <div className="map-view">
                        <GoogleMapComponent
                          className="details-map"
                          zoom={12}
                          newCenter={{ ...this.state.property.coordinates }}
                          markers={[{ ...this.state.property.coordinates }]}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 p-0 p-md-3">
                    {this.state.property.houseRules?.curfew && (
                      <div className="curfew-wrp d-none d-md-flex">
                        <div className="curfew-ico">
                          <img
                            src={require("../../assets/images/calender.svg")}
                            alt=""
                          />
                        </div>
                        <Curfew_UI />
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-8">
                    <Options_UI items={this.formValues.options} />
                    {this.hasYes(this.formValues.requirements) && (
                      <div className="row">
                        <div className="section-break-div mb-4 mb-md-0"></div>
                        <div className="col-lg-12">
                          <h4 className="sub-title">
                            Sober Living Requirements
                          </h4>
                          <Options_UI items={this.formValues.requirements} />
                        </div>
                      </div>
                    )}
                    {this.hasYes(this.formValues.houseRules) && (
                      <div className="row">
                        <div className="section-break-div mb-4 mb-md-0"></div>
                        <div className="col-lg-12">
                          <h4 className="sub-title">House Rules</h4>
                          {this.state.property.houseRules?.curfew && (
                            <div className="curfew-wrp d-flex d-md-none mb-0 pb-0">
                              <div className="curfew-ico">
                                <img
                                  src={require("../../assets/images/calender.svg")}
                                  alt=""
                                />
                              </div>

                              <Curfew_UI isMobile={true} />
                            </div>
                          )}
                          <Options_UI items={this.formValues.houseRules} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* Review */}

                <div className="row">
                  <div className="section-break-div mb-4 mb-md-0"></div>
                  <div className="col-lg-6 col-xl-8">
                    <h4 className="sub-title">Reviews</h4>
                    {/* {this.props.isHost &&
                    this.state.property.isSubscribed === false ? (
                      ""
                    ) : (
                      <h4 className="sub-title">Reviews</h4>
                    )} */}

                    {!this.props.isHost && (
                      <>
                        <div className="row">
                          <div className="col-md-8">
                            <ReviewReplyForm
                              isPro={this.state.property.isSubscribed}
                              submitReview={this.onSubmitReview}
                              userName={this.props.user?.name}
                              propertyName={this.state.property.name}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {this.state.property.isSubscribed
                      ? this.state.reviews.map((review, index) => {
                          return (
                            <ReviewDetailsComponent
                              isPro={this.state.property.isSubscribed}
                              isHost={this.props.isHost}
                              review={review}
                              key={index}
                              submitReview={this.onSubmitReviewReply}
                              replyCallback={(id) =>
                                this.setState({ reviewId: id })
                              }
                            />
                          );
                        })
                      : this.state.reviews
                          .filter((review) => review.user.id === userID?.id)
                          .map((review, index) => {
                            return (
                              <ReviewDetailsComponent
                                isPro={this.state.property.isSubscribed}
                                isHost={this.props.isHost}
                                review={review}
                                key={index}
                                submitReview={this.onSubmitReviewReply}
                                replyCallback={(id) =>
                                  this.setState({ reviewId: id })
                                }
                              />
                            );
                          })}

                    {this.state.reviews.length > 0 &&
                      this.state.property.isSubscribed === true &&
                      this.props.isHost &&
                      this.state.reviewId &&
                      false && (
                        <div className="row">
                          <div className="col-md-8">
                            <h4 className="sub-title">Reply review</h4>
                            <ReviewReplyForm
                              submitReview={this.onSubmitReviewReply}
                              userName={this.props.user?.name}
                              isHost={true}
                            />
                          </div>
                        </div>
                      )}

                    {(this.props.isHost &&
                      this.state.property.isSubscribed === false) ||
                    (userID === null &&
                      this.state.property.isSubscribed === false) ||
                    (!this.props.isHost &&
                      this.state.property.isSubscribed === false &&
                      this.state.reviews.length === 0) ||
                    this.state.reviews.length === 0 ? (
                      <div className="review-no-records position-relative text-center my-4 pb-2">
                        <img src={noReviewImg} className="mb-5 mt-2" />
                        <h2>OOPS, NO REVIEWS YET...</h2>
                        <p>Reviews will show up here</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {this.state.recommendedFacilities?.length > 0 &&
                    this.state.property.isSubscribed === true && (
                      <div className="col-md-7 col-lg-6 col-xl-4">
                        <RecommendedFacility
                          settings1={this.settings1}
                          recommendedSlider={this.recommendedSlider}
                          recommendedFacilities={
                            this.state.recommendedFacilities
                          }
                        />
                      </div>
                    )}
                </div>
              </div>
            </section>
          </div>
        )}
      </>
    );
  }

  getSliderSettings(sliderImageCount = 3) {
    return {
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: sliderImageCount,
      slidesToScroll: sliderImageCount,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: sliderImageCount,
            slidesToScroll: sliderImageCount,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 987,
          settings: {
            slidesToShow: sliderImageCount,
            slidesToScroll: sliderImageCount,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    };
  }

  getValueOfItem(key) {
    if (typeof key === "function") {
      return key();
    }
    let keys = [];
    let value = "";
    if (key.indexOf(".") > 0) {
      keys = key.split(".");
      value = this.state.property[keys[0]][keys[1]];
    } else if (key == "rentCost") {
      value = getPriceFromItem(this.state.property, true);
    } else {
      value = this.state.property[key];
    }
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    } else if (typeof value === "string") {
      return Ucfirst(value);
    }
    return value;
  }

  getImages() {
    let images = getImagesFromItem(this.state.property);
    return images;
  }

  onSubmitReview = (review) => {
    review.property = this.state.property.id;
    return this.props.addReview(review);
  };

  onSubmitReviewReply = (review) => {
    const reviewID = this.state.reviewId;
    this.setState({ reviewId: null });
    return this.props.addReviewReply(review, reviewID, this.livingId);
  };

  bookmarkLiving = () => {
    if (this.props.user) {
      this.props
        .bookmarkLiving(this.livingId)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {});
    } else {
      this.context.showLogin(true);
    }
  };

  removeBookmarkLiving = () => {
    if (this.props.user) {
      this.props
        .removeBookmark(this.livingId)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {});
    } else {
      this.context.showLogin(true);
    }
  };

  onMarkAsViewed = () => {
    if (this.props.user) {
      this.props.markAsViewed(this.livingId);
    }
  };

  hasYes = (items) => {
    return (
      items.filter((item) => this.getValueOfItem(item.value) !== "No").length >=
      1
    );
  };

  contactHome = () => {
    if (this.props.user) {
      if (!this.props.isContactingHome) {
        this.props
          .contactHome(this.livingId)
          .then((res) => {
            // navigate to chat page
            this.props.history.push(`/chats/${res.id}?contactHome=true`);
          })
          .catch((err) => {});
      }
    } else {
      this.context.showLogin(true);
    }
  };

  fetchDetails = () => {
    if (!this.props.isFetchingLiving && this.props.user) {
      this.context.showLoader("Get Sober Living ...");
      this.props
        .fetchLivingById(this.livingId)
        .then((data) => {})
        .catch((err) => {
          if (
            err?.response?.data?.data?.length > 0 &&
            err?.response?.data?.data[0]
          ) {
            this.setState({ isError: true });
            this.setState({ errorMessage: err?.response?.data?.data[0] });
          }
        })
        .finally(() => {
          this.setState({ isDeleted: this.props.isDeleted });
          setTimeout(() => {
            this.setState({ ...this.props.properties?.get(this.livingId) });
            this.context.hideLoader();
          }, 2000);
          if (this.context.subscribePage === "paymentPage") {
            this.setState(
              {
                pageReload: true,
              },
              () => {
                this.loadPage();
              }
            );
          }
        });
    } else if (!this.props.user) {
      this.context.showLoader("Get Sober Living Reviews ...");

      this.props
        .onFetchingLivingByIdWithoutLogin(this.livingId)
        .then(() => {})
        .catch((err) => {
          if (
            err.response.data?.data?.length > 0 &&
            err.response.data?.data[0]
          ) {
            this.setState({ isError: true });
            this.setState({ errorMessage: err.response.data?.data[0] });
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.setState({ ...this.props.properties?.get(this.livingId) });
            this.context.hideLoader();
          }, 2000);
        });
    }
  };

  handlePpcClick() {
    const { state, dispatch, comp } = this.props;
    if (this.props?.user?.role == "regular") {
      this.props.ppcClickBySignInUser({
        facilityOrSoberLivingId: this.props?.location?.state?.property?.id,
      });
    } else {
      this.props.ppcClickNonAuth({
        facilityOrSoberLivingId: this.props?.location?.state?.property?.id,
      });
    }
  }
}

export default connect(
  ({ living, auth, chat, payment, home }) => ({
    user: auth.user,
    properties: living.properties,
    isFetchingLiving: living.fetchingLiving,
    addingReview: living.addingReview,
    user: auth.user,
    isHost: auth.isHost,
    addingReviewReply: living.addingReviewReply,
    isBookmarkAdding: living.bookmarkAdding,
    isBookmarkRemoving: living.bookmarkRemoving,
    isContactingHome: living.isContactingHome,
    isFetchingChats: chat.fetchingChats,
    isFetchingActiveChat: chat.fetchingActiveChat,
    reviewsForAnonymousUser: living.reviewsForAnonymousUser,
    isDeleted: living.isDeleted,
    isFetching: payment.fetchingCards,
    cards: payment.cards,
    subscribePlans: home.subscribePlan,
  }),
  (dispatch) => {
    return {
      ...bindActionCreators(
        { ...livingAction, ...homeAction, ...DashboardAction },
        dispatch
      ),
    };
  }
)(LivingDetails);
