import React, { useContext, useState } from "react";
import { Modal, Button } from "react-bootstrap";
// import deleteImg from "../../assets/images/delete-primary.svg"
import { SpinnerContext } from "../../components/spinner/spinner.component";
const DeleteConfirmationModal = ({
  open,
  onCancel,
  onDelete,
  php,
  detox,
  residential,
}) => {
  const [isLoading, setIsLoading] = useState(false); // To handle delete button animation
  const { showLoader } = useContext(SpinnerContext);
  const handleDelete = () => {
    if (php) {
      showLoader("Deleting php Center..");
    }
    if (detox) {
      showLoader("Deleting detox Center..");
    }
    if (residential) {
      showLoader("Deleting residential Center..");
    }
    setIsLoading(true); // Activate loading animation
    onDelete(); // Call the onDelete function passed from the parent component
    onCancel();
  };
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const originalString = "Are you sure you want to cancel your subscription?";
  const transformedString = capitalizeFirstLetter(originalString);
  const trashIconStyle = {
    position: "absolute",
    overflow: "hidden",
    left: "-4px",
    right: "-4px",
    bottom: "100%",
    height: "40px",
    zIndex: "1",
    transform: "translateY(2px)",
  };

  return (
    <>
      {open && (
        <div
          className="modal upgrade-plan cancel-subscription-modal fade show"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="upgrade-plan-content">
                <h3>Are you sure you want to delete?</h3>
                <span className="loading-msg">
                  The information regarding this facility will be removed and
                  you will no longer have access to editing this listing or
                  viewing the data and disable your current subscription plan
                  features.
                </span>
              </div>
              <div className="modal-footer">
                <button type="submit" className="modal-btn" onClick={onCancel}>
                  No
                </button>
                <button
                  type="submit"
                  className="modal-btn confirm-btn"
                  onClick={handleDelete}
                  disabled={isLoading}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteConfirmationModal;
