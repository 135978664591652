import { phpActionType } from "../actions";

const initialDetoxState = {
  addingPhp: false,
  fetching: false,
  editingPhp: false,
  php: [],
  fetchingPhpPlan: true,
  phpPlan: [],
  priceId: null,
  priceAmount: null,
  facilityInfo: {},
  phpdetail: null,
  isChange: false,
};

export const phpReducer = (state = initialDetoxState, action) => {
  switch (action.type) {
    case phpActionType.Php_Adding:
      return { ...state, addingPhp: true };
    case phpActionType.Php_Add:
      const { php } = action.payload;
      return { ...state, addingPhp: false, php: php };

    case phpActionType.fetch_Php_ById_ActionType:
      return { ...state, fetching: true };
    case phpActionType.fetch_Php_ById_Success:
      const { phpdetailsById } = action.payload;
      return { ...state, fetching: false, phpdetail: phpdetailsById };
    case phpActionType.fetch_Php_ById_Failure:
      return { ...state, fetching: false, error: action.payload.error };

    case phpActionType.Php_Editing:
      return { ...state, editingPhp: true };
    case phpActionType.Php_Edited:
      return { ...state, editingPhp: false };

    case phpActionType.SubscriptionPlan_Fetching:
      return { ...state, fetchingPhpPlan: true };
    case phpActionType.SubscriptionPlan_Fetched:
      return { ...state, fetchingPhpPlan: false, phpPlan: action.payload };

    case phpActionType.PhpBasicPlan_Set:
      return {
        ...state,
        priceId: action.payload?.id,
        priceAmount: action?.payload?.price,
      };
    case phpActionType.PhpProPlan_Set:
      return {
        ...state,
        priceId: action.payload?.id,
        priceAmount: action?.payload?.price,
      };
    case phpActionType.PhpFacilityType_Set:
      return {
        ...state,
        facilityInfo: {
          facilitytype: action?.payload?.facilitytype,
          id: action?.payload?.id,
          name: action?.payload?.name,
        },
      };

    case phpActionType.Php_Sub:
      return { ...state, isChange: action.payload };

    case phpActionType.RESET_DATA:
      return { ...state, facilityInfo:{} };

    default:
      return state;
  }
};
