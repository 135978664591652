import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import backImgBlack from "../../assets/images/back.svg";
import backImgBlackWhite from "../../assets/images/back-white-arrow.svg";
import GoogleMapComponent from "../../components/map/google-map.component";
import Slider from "react-slick";
import NoImage from "../../assets/images/no-image.svg";
import NoImageDetail from "../../assets/images/No-image-details-screen.svg";
import children from "../../assets/images/Children.svg";
import couples from "../../assets/images/Couples.svg";
import pregWomen from "../../assets/images/Pregnant women.svg";
import Scholarships from "../../assets/images/Scholarship.svg";
import serviceDogs from "../../assets/images/Service Dogs.svg";

import showAll from "../../assets/images/show-all.svg";
import pinImg from "../../assets/images/ic_pin_small.svg";
import HostReply from "../common/HostReply";
import starImg from "../../assets/images/star-24px.svg";
import { residentialAction } from "../../actions/index";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerContext } from "../../components/spinner/spinner.component";
import {
  formatCamelCaseToReadabcommonAmenities,
  formatCamelCaseToReadabpersonalAmenities,
  formatCamelCaseToReadablemedication,
  formatTestKeyToReadableiopphp,
  formatCamelCaseToReadabcodtiontreated,
  formatCamelCaseToReadabletreatmentapproaches,
  formatCamelCaseToReadableadditionaservices,
  formatCamelCaseToReadableteamconsists,
  addCommasToPrice,
  formatCamelCaseToReadablesubstutanceorder,
  formatCamelCaseToReadableinsurance,
  formatCamelCaseToReadableadditionaservicesforesidentail,
  formatCamelCaseToReadableadditionaservicesforesidentailResidential,
  formatString,
} from "../../constants/utils";
import usdIcon from "../../../src/assets/images/usd.svg";
import phoneOrangeIcon from "../../../src/assets/images/call.svg";
import virtualTourIcon from "../../assets/images/virtual-tour.svg";
import aboutTitle from "../../../src/assets/images/aboutTitle.svg";
import stayRange from "../../../src/assets/images/Range of term of stay.svg";
import maxCapacity from "../../../src/assets/images/Maximum capacity.svg";
import specializesIcon from "../../../src/assets/images/specializes-in.svg";
import servicesOfferedIcon from "../../../src/assets/images/additional-services-offered.svg";
import commonAmenities from "../../../src/assets/images/Common Amenities.svg";
import clientToClinical from "../../../src/assets/images/Client to clinical team ratio.svg";
import primaryLanguage from "../../../src/assets/images/Primary language.svg";
import otherLanguages from "../../../src/assets/images/Other languages.svg";
import genderTypeIcon from "../../../src/assets/images/gender-type.svg";
import conditionsIcon from "../../../src/assets/images/conditions-treated.svg";
import medicationIcon from "../../../src/assets/images/medication-therapy.svg";
import approachesIcon from "../../../src/assets/images/treatment-approaches.svg";
import assessmentIcon from "../../../src/assets/images/assessment-pre-treatment.svg";
import testingIcon from "../../../src/assets/images/testing.svg";
import smokingIcon from "../../../src/assets/images/facility-smoking-policy.svg";
import staffIcon from "../../../src/assets/images/staff-associated.svg";
import insuranceIcon from "../../../src/assets/images/insurance-accepted.svg";
import Viewshowmorebutton from "../../components/button/Viewshowmorebutton";

import ClaimFacility from "../home/claimFacilityView";
import Questions from "../../components/viewField/questions.component";
const ResidencialDetails = () => {
  const residentialdetail = useSelector(
    (state) => state?.residential?.residentialByID
  );
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const comp = useParams();
  const dispatch = useDispatch();
  const [GetData, setGetData] = useState([]);
  const [showmodel, setshowmodel] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  useEffect(() => {
    ShowData(comp?.id);
  }, [comp?.id]);

  const ShowData = async (id) => {
    showLoader("Geting residential center please wait ");
    const data = await dispatch(residentialAction.getResidencialById(id));
    document.title = data?.name;
    setGetData(data);

    setCoordinates({
      lat: data?.coordinates?.lat,
      lng: data?.coordinates?.lat,
    });
    hideLoader("");
  };
  var gallerySlider = {
    dots: true,
    infinite: false,
    speed: 300,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    swipeToSlide: true,
    afterChange: (current) => setCurrentSlide(current),
  };
  const formatPhoneNumber = (input) => {
    const phoneNumber = input?.replace(/\D/g, "");
    if (!phoneNumber) return "";

    let formattedPhoneNumber = `(${phoneNumber.slice(0, 3)})`;
    if (phoneNumber.length > 3) {
      formattedPhoneNumber += `-${phoneNumber.slice(3, 6)}-`;
    }
    if (phoneNumber.length > 6) {
      formattedPhoneNumber += `${phoneNumber.slice(6, 10)}`;
    }
    if (phoneNumber.length > 10) {
      formattedPhoneNumber += `-${phoneNumber.slice(10, 15)}`;
    }

    return formattedPhoneNumber;
  };

 
  return (
    <>
      <section className="form-view-banner">
        <div className="banner-image">
          {GetData?.photos?.length > 0 ? (
            GetData?.photos?.map((obj, index) => {
              return (
                <>
                  <React.StrictMode key={index}>
                    <img src={obj?.path} className="img-fluid" alt="" />
                  </React.StrictMode>
                </>
              );
            })
          ) : (
            <img src={NoImageDetail} className="img-fluid" alt="" />
          )}
        </div>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-md-12 h-100">
              <div className="photos-banner-content d-flex flex-column justify-content-between h-100">
                <Link to="/profile" role="button" className="form-view-back">
                  <img src={backImgBlackWhite} className="img-fluid" alt="" />
                </Link>
                {GetData?.photos?.length !== 0 && (
                  <div className="show-all-photos w-100 text-right">
                    <button
                      type="button"
                      className="btn btn-default d-flex align-items-center radius-20 m-0 ml-auto"
                      onClick={() => setshowmodel(true)}
                    >
                      {" "}
                      <img src={showAll} className="img-fluid" alt="" />
                      Show all Photos
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="form-view-tabs-sec with-subscription-plan pt-0">
        <div className="form-view-content">
          <div className="form-view-top position-relative">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="facility-content d-flex flex-wrap justify-content-center justify-content-md-between pt-md-4">
                    <div className="facility-info pt-md-3">
                      {/* <h2>{GetData?.name?.toUpperCase()}</h2> */}
                      <h3 className="mt-0">
                        {GetData?.name
                          ?.split(" ")
                          ?.map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                      </h3>
                      <span className="location">
                        <img src={pinImg} className="img-fluid m-0" alt="" />
                        {GetData?.geocodingResult?.cityName}
                        {", "}
                        {GetData?.geocodingResult?.stateShort}
                        {", "}
                        {GetData?.geocodingResult?.country}
                      </span>
                      {(GetData?.rentRange?.from || GetData?.rentRange?.to) && (
                        <span className="location">
                          {/* <img src={usdIcon} className="img-fluid m-0" alt="" /> */}
                          ${addCommasToPrice(GetData?.rentRange?.from)} - $
                          {addCommasToPrice(GetData?.rentRange?.to)}
                        </span>
                      )}
                      {(GetData?.preSalePlanType === "pro" ||
                        GetData?.subscriptionDetail?.subscriptionType ===
                          "pro" ||
                        GetData?.subscriptionDetail?.subscriptionType ===
                          "basic") && (
                        <span className="location">
                          <img src={starImg} className="img-fluid m-0" alt="" />
                          {GetData?.rating?.value !== 0 ? (
                            <>
                              <span>{GetData?.rating?.value?.toFixed(1)}</span>
                            </>
                          ) : (
                            <>
                              <span>0.0</span>
                            </>
                          )}
                        </span>
                      )}
                    </div>
                    <div className="info-icon pt-2 pt-md-3">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <a
                              href="#"
                              className="d-none d-md-block text-right"
                            ></a>

                            {(GetData?.isClaimRequest ||
                              GetData?.isSubscribed ||
                              GetData?.isPreSaleSubscribed) && (
                              <ClaimFacility
                                facilityId={GetData?.id}
                                Facilityname={GetData?.name}
                                dashboard="yes"
                                flag={
                                  GetData?.isSubscribed ||
                                  GetData?.isPreSaleSubscribed
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="active-plan mt-4 mb-5">
            <div className="container">
              <div className="plan-wrp">
                {GetData?.isPreSaleSubscribed || GetData?.isSubscribed ? (
                  <div className="plan-wrp">
                    <img
                      src={require("../../assets/images/email-marketing.svg")}
                      className="mr-3"
                      alt=""
                    />
                    <div className="plan-content">
                      <span>
                        Active Plan{" "}
                        {GetData?.preSalePlanType === "basic" ||
                        GetData.subscriptionDetail?.subscriptionType === "basic"
                          ? "Basic"
                          : "Pro"}
                      </span>
                      {GetData?.preSalePlanType && (
                        <h3>
                          ${GetData?.preSalePlanType === "basic" ? 19 : 39}{" "}
                        </h3>
                      )}
                      {GetData?.isSubscribed && (
                        <div>
                          <span>${GetData.subscriptionDetail?.price}</span>
                          {"   /  "}
                          <span>
                            {GetData.subscriptionDetail?.interval == "month"
                              ? "Monthly"
                              : "Yearly"}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="plan-wrp">
                    <img
                      src={require("../../assets/images/Subscription pending icon.svg")}
                      className="mr-3"
                      alt=""
                    />
                    <div className="plan-content">
                      {" "}
                      <span className="mb-0">
                        Subscription pending. Subscribe your facility to get
                        listed on the homepage.
                      </span>{" "}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-view-detail">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="facility-content">
                    <div className="about-content pt-0 pb-2">
                      {GetData?.about && (
                        <h3 className="d-flex align-items-center mt-0">
                          <img src={aboutTitle} className="img-fluid" alt="" />{" "}
                          About
                        </h3>
                      )}

                      <p>{GetData?.about}</p>
                      {GetData?.webUrl !== "https://www." &&
                        GetData?.webUrl !== "" && (
                          <div
                            className="about-list"
                            onClick={() =>
                              window.open(GetData?.webUrl, "_blank")
                            }
                          >
                            <div className="about-link">
                              <img
                                src={virtualTourIcon}
                                sizes="20px"
                                className="img-fluid"
                                alt=""
                              />{" "}
                              <span>{GetData?.webUrl}</span>
                            </div>
                          </div>
                        )}
                      {GetData?.phoneNumber && (
                        <div
                          className="about-list"
                          onClick={() =>
                            (window.location.href = `tel:${GetData?.phoneNumber}`)
                          }
                        >
                          <div className="about-link">
                            <img
                              src={phoneOrangeIcon}
                              sizes="20px"
                              className="img-fluid"
                              alt=""
                            />{" "}
                            <span>
                              {formatPhoneNumber(GetData?.phoneNumber)}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="about-list">
                        {GetData?.subscriptionDetail?.subscriptionType === "pro"
                          ? GetData?.virtualHouseLink && (
                              <>
                                {GetData.virtualHouseLink !== "https://www." &&
                                  GetData.virtualHouseLink !== "" &&
                                  GetData.virtualHouseLink !==
                                    "http://www." && (
                                    <div className="about-link" onClick={() => window.open(GetData?.virtualHouseLink, "_blank")}>
                                      <img
                                        src={virtualTourIcon}
                                        sizes="20px"
                                        className="img-fluid"
                                        alt=""
                                      />
                                      <span>{GetData.virtualHouseLink}</span>
                                    </div>
                                  )}
                              </>
                            )
                          : ""}
                      </div>
                    </div>
                    <h3 className="mt-2">Location</h3>
                    <span className="location">
                      <img src={pinImg} className="img-fluid m-0" alt="" />
                      {GetData?.geocodingResult?.cityName}
                      {", "}
                      {GetData?.geocodingResult?.stateShort}
                      {", "}
                      {GetData?.geocodingResult?.country}
                    </span>
                    <div className="map-view form-group mt-3 pt-1">
                      <GoogleMapComponent
                        className="details-map"
                        markers={[
                          residentialdetail?.coordinates || { lat: 0, lng: 0 },
                        ]}
                        newCenter={{
                          lat: residentialdetail?.coordinates?.lat
                            ? residentialdetail?.coordinates?.lat
                            : 0,
                          lng: residentialdetail?.coordinates?.lng
                            ? residentialdetail?.coordinates?.lng
                            : 0,
                        }}
                        handleClick={(e) => {
                          setCoordinates({ lat: e.lat, lng: e.lng });
                        }}
                      />
                    </div>
                  </div>
                  {(GetData?.allowableStay?.from ||
                    GetData?.allowableStay?.to) && (
                    <div className="facility-content">
                      <h3 className="d-flex align-items-center">
                        {" "}
                        <img src={stayRange} className="img-fluid" alt="" />
                        Range Of Term Of Stay{" "}
                      </h3>
                      <ul className="d-flex align-items-start flex-wrap">
                        <li>
                          {GetData?.allowableStay?.from} days -{" "}
                          {GetData?.allowableStay?.to} days
                        </li>
                      </ul>
                    </div>
                  )}

                  {GetData?.maximumCapacity !== 0 &&
                    GetData?.maximumCapacity !== null && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img src={maxCapacity} className="img-fluid" alt="" />
                          Maximum Capacity
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          <li>{GetData?.maximumCapacity}</li>
                        </ul>
                      </div>
                    )}

                  {GetData?.specialization && (
                    <Viewshowmorebutton
                      GetData={GetData?.specialization}
                      iocn={specializesIcon}
                      name="Specialize In"
                    />
                  )}
                  {GetData?.additionalServiceOffered &&
                    Object.values(GetData?.additionalServiceOffered).some(
                      (value) => value === true
                    ) && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img
                            src={servicesOfferedIcon}
                            className="img-fluid"
                            alt=""
                          />
                          Additional Services Offered
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          {GetData?.additionalServiceOffered &&
                            Object.entries(
                              GetData?.additionalServiceOffered
                            )?.map(([key, value]) => {
                              if (value) {
                                return (
                                  <li key={key}>
                                    {formatCamelCaseToReadableadditionaservicesforesidentailResidential(
                                      key
                                    )}
                                  </li>
                                );
                              } else {
                                return null;
                              }
                            })}
                        </ul>
                      </div>
                    )}

                  {GetData?.personalAmenities && (
                    <Viewshowmorebutton
                      GetData={GetData?.personalAmenities}
                      iocn={commonAmenities}
                      name="Personal Amenities"
                    />
                  )}
                  {GetData?.commonAmenities && (
                    <Viewshowmorebutton
                      GetData={GetData?.commonAmenities}
                      iocn={commonAmenities}
                      name="Common Amenities"
                    />
                  )}

                  {GetData?.primaryLanguage != "" &&
                    GetData?.primaryLanguage !== null && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img
                            src={primaryLanguage}
                            className="img-fluid"
                            alt=""
                          />
                          Primary language
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          <li>
                            {GetData?.primaryLanguage &&
                              GetData.primaryLanguage.charAt(0).toUpperCase() +
                                GetData.primaryLanguage.slice(1)}
                          </li>
                        </ul>
                      </div>
                    )}

                  {GetData?.isOfferedOtherlanguages &&
                    GetData?.otherlanguages?.length !== 0 &&
                    residentialdetail?.otherlanguages?.length !== 0 && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img
                            src={otherLanguages}
                            className="img-fluid"
                            alt=""
                          />
                          Other languages
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          {GetData?.otherlanguages?.map((language, key) => (
                            <li key={key}>
                              {language.charAt(0).toUpperCase() +
                                language.slice(1)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                  {GetData?.genderSpecific === true &&
                    GetData.gender !== "anybody" && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img
                            src={genderTypeIcon}
                            className="img-fluid"
                            alt=""
                          />
                          Gender Type
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          <li>
                            {GetData?.gender === "men"
                              ? "Male"
                              : "" || GetData?.gender === "women"
                              ? "Female"
                              : "" || GetData?.gender === "lgbtq"
                              ? "LGBTQ"
                              : ""}
                          </li>
                        </ul>
                      </div>
                    )}

                  {GetData?.conditionTreated &&
                    Object.values(GetData?.conditionTreated).some(
                      (value) => value === true
                    ) && (
                      <Viewshowmorebutton
                        GetData={GetData?.conditionTreated}
                        iocn={conditionsIcon}
                        name="Conditions Treated"
                      />
                    )}
                  {GetData?.medicationTherapy &&
                    Object.values(GetData?.medicationTherapy).some(
                      (value) => value === true
                    ) && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img
                            src={medicationIcon}
                            className="img-fluid"
                            alt=""
                          />
                          Medication Therapy
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          {GetData?.medicationTherapy &&
                            Object.entries(GetData?.medicationTherapy)?.map(
                              ([key, value]) => {
                                if (value) {
                                  const formattedKey = key;
                                  return (
                                    <li key={key}>
                                      {formatCamelCaseToReadablemedication(
                                        formattedKey
                                      )}
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                        </ul>
                      </div>
                    )}

                  {GetData?.treatmentApproaches &&
                    Object.values(GetData?.treatmentApproaches).some(
                      (value) => value === true
                    ) && (
                      <Viewshowmorebutton
                        GetData={GetData?.treatmentApproaches}
                        iocn={approachesIcon}
                        name="Treatment Approaches"
                        formatstring={
                          formatCamelCaseToReadabletreatmentapproaches
                        }
                      />
                    )}
                  {GetData?.preTreatment &&
                    Object.values(GetData?.preTreatment).some(
                      (value) => value === true
                    ) && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img
                            src={assessmentIcon}
                            className="img-fluid"
                            alt=""
                          />
                          Assessment/ Pre-treatment
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          {GetData?.preTreatment &&
                            Object.entries(GetData?.preTreatment)?.map(
                              ([key, value]) => {
                                if (value) {
                                  return (
                                    <li key={key}>
                                      {formatCamelCaseToReadabletreatmentapproaches(
                                        key
                                      )}
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                        </ul>
                      </div>
                    )}

                  {GetData?.testing &&
                    Object.values(GetData?.testing).some(
                      (value) => value === true
                    ) && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img src={testingIcon} className="img-fluid" alt="" />
                          Testing
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          {GetData?.testing &&
                            Object.entries(GetData?.testing)?.map(
                              ([key, value]) => {
                                if (value) {
                                  const formattedKey =
                                    formatTestKeyToReadableiopphp(key);
                                  return <li key={key}>{formattedKey}</li>;
                                } else {
                                  return null;
                                }
                              }
                            )}
                        </ul>
                      </div>
                    )}

                  {GetData?.smokingPolicy &&
                    Object.values(GetData?.smokingPolicy).some(
                      (value) => value === true
                    ) && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img src={smokingIcon} className="img-fluid" alt="" />
                          Facility Smoking Policy
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          {GetData?.smokingPolicy &&
                            Object.entries(GetData?.smokingPolicy)?.map(
                              ([key, value]) => {
                                if (value) {
                                  const formattedKey = key;
                                  return (
                                    <li key={key}>
                                      {formatString(formattedKey)}
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                        </ul>
                      </div>
                    )}

                  <Questions
                    image={serviceDogs}
                    heading="Allows Service Dogs"
                    details={GetData?.serviceDogs}
                  />
                  <Questions
                    image={couples}
                    heading="Allows Couples"
                    details={GetData?.couples}
                  />
                  <Questions
                    image={pregWomen}
                    heading="Allows Pregnant Women"
                    details={GetData?.pregnantWomen}
                  />
                  <Questions
                    image={children}
                    heading="Allows Children"
                    details={GetData?.children}
                  />
                  <Questions
                    image={Scholarships}
                    heading="Offers Scholarships"
                    details={GetData?.offersScholarships}
                  />

                  {GetData?.teamConsist &&
                    Object.values(GetData?.teamConsist).some(
                      (value) => value === true
                    ) && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img src={staffIcon} className="img-fluid" alt="" />
                          Staff Associated
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          {GetData?.teamConsist &&
                            Object.entries(GetData?.teamConsist)?.map(
                              ([key, value]) => {
                                if (value) {
                                  return (
                                    <li key={key}>
                                      {formatCamelCaseToReadableteamconsists(
                                        key
                                      )}
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                        </ul>
                      </div>
                    )}

                  {GetData?.clients && GetData?.clinical && (
                    <div className="facility-content">
                      <h3 className="d-flex align-items-center">
                        <img
                          src={clientToClinical}
                          className="img-fluid"
                          alt=""
                        />
                        Client To Clinical Team Ratio
                      </h3>
                      <ul className="d-flex align-items-start flex-wrap">
                        <li>
                          {GetData?.clients}{" "}
                          {GetData?.clients === 1 ? "Client" : "Clients"} :{" "}
                          {GetData?.clinical}{" "}
                          {GetData.clinical === 1 ? "Clinician" : "Clinicians"}{" "}
                        </li>
                      </ul>
                    </div>
                  )}

                  {GetData?.insurance &&
                    Object.values(GetData?.insurance).some(
                      (value) => value === true
                    ) && (
                      <div className="facility-content">
                        <h3 className="d-flex align-items-center">
                          <img
                            src={insuranceIcon}
                            className="img-fluid"
                            alt=""
                          />
                          Insurance Accepted
                        </h3>
                        <ul className="d-flex align-items-start flex-wrap">
                          {GetData?.insurance &&
                            Object.entries(GetData?.insurance)?.map(
                              ([key, value]) => {
                                if (value) {
                                  return (
                                    <li key={key}>
                                      {formatCamelCaseToReadableinsurance(key)}
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                        </ul>
                      </div>
                    )}

                  <button
                    type="button"
                    className="btn btn-primary radius-20 m-0 px-4 mt-4"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(`${GetData?.verifyInsurance}`, "_blank");
                    }}
                    disabled={
                      GetData?.verifyInsurance?.trim() === "" ||
                      GetData?.verifyInsurance?.trim() === "https://www."
                    }
                  >
                    Verify Insurance
                  </button>

                  <HostReply chatid={comp?.id} type="residential" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <>
        {showmodel && (
          <div
            className={`modal fade photo-gallery-modal ${
              showmodel ? "show d-block" : ""
            }`}
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="gallery-content">
                  <div className="modal-header border-0 p-0">
                    <div className="modal-header-top d-flex w-100 align-items-center">
                      <a role="button" className="d-block d-md-none">
                        <img src={backImgBlack} alt="" />
                      </a>
                      <a
                        className="cancel-btn d-flex align-items-center"
                        role="button"
                        onClick={() => setshowmodel(false)}
                      >
                        <img
                          src={require("../../assets/images/cancle.svg")}
                          className="img-fluid"
                          alt=""
                        />{" "}
                        Close
                      </a>
                      {/* <div className="pagination">
                        {currentSlide + 1} / {GetData?.photos?.length}
                      </div> */}
                    </div>
                  </div>
                  <Slider {...gallerySlider} className="gallery-slider">
                    {GetData?.photos?.map((obj, index) => {
                      return (
                        <>
                          <div className="slider-item" key={index}>
                            <img src={obj?.path} className="img-fluid" alt="" />
                            {/* <h3>{GetData?.name} Room</h3> */}
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default ResidencialDetails;
