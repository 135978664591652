import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ReactHelmet from "../../components/react-helmet/react-helmet";
import AddResidential from "./add-residential";
import ResidencialDetails from "./view-residencialDetail";
class Residential extends Component {
  render() {
    const AdminSecuredRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>

          this.props.isAuthorized && this.props.isHost ? (
            <Component {...props} />
          ) : (
            <Redirect to="/?isSignIn=true" />
          )
        }
      />
    );

    return (
      <>
        <ReactHelmet
          title={"Taste Recovery"}
          description={
            "Taste Recovery is a free, nationwide service for finding safe, peer-reviewed sober living homes. Search hundreds of listings now for free."
          }
        />

        <Switch>
          <Route
            path="/residentialfacility/view/:id"
            exact={true}
            component={ResidencialDetails}
          />
          <AdminSecuredRoute
            path="/residentialfacility/edit/:id/:currentStep?"
            exact={true}
            component={AddResidential}
          />
          <AdminSecuredRoute
            path="/residentialfacility/add-residential/:currentStep?"
            exact={true}
            component={AddResidential}
          />
        </Switch>
      </>
    );
  }
}

export default connect(({ auth }) => ({
  isAuthorized: auth?.authToken != null && auth.user != null,
  isHost: auth?.isHost,
}))(Residential);
